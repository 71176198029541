#cars {
     width: 100%;
     height: 62%;
     border: 1px solid rgba(0, 0, 0, 0.125);
     color: #7E7E7E;
     border-radius: 5px;
}

.btn-primary:hover {
     background-color: #ffcc00 !important;
     border-color: #ffcc00 !important;
     color: black !important;
}

p {
     margin-bottom: 5px !important;
}

.download-button {
     background-color: #14b8a6 !important;
     width: 100%;
     margin-top: 10px;
     border-radius: 10px;
     font-weight: 500;
}

/* .download-button:hover {
     background-color: #ffcc00 !important;
     border-color: #ffcc00 !important;
     color: black !important;
} */

.row {
     justify-content: center;
}

.toast {
     border-radius: 10px;
     background: #14b8a6 !important;
     color: #ffffff !important;
     margin-top: 1rem;
     width: max-content;
     height: 50px;
     font-size: large;
     text-align: left;
}

.toastContent svg {
     width: 25px;
     height: 25px;
}